<script setup>
import "animate.css";
import { defineProps, onMounted, onUnmounted, ref } from "vue";

import { useL24Store } from "../store/l24";
import UAParser from "ua-parser-js";
import axios from "axios";
import UiHls from "./ui/hls.vue";

const props = defineProps({
	options: {
		type: Object,
	},
});

const element = ref({
	x: -200,
	y: -320,
	width: 200,
	height: 360,
	isActive: false,
	round: 15,
});

const toLoad = ref(false);
const initLoad = ref(false);
const isMobile = ref(false);
const isPlay = ref(true);
const isMuted = ref(true);
const isHls = ref(false);
const isVideoReady = ref(false);

const wstore = ref({
	privew: false,
	innerMode: false,
	contentNo: null,
	userNo: null,
	productNo: null,
});

const setOptions = (options) => {
	initLoad.value = false;
	wstore.value = { ...wstore.value, ...options };
};

defineExpose({
	setOptions,
});

let l24config = null;
let shorts = ref(null);
const getShortsInfo = async () => {
	if (wstore.value.productNo && wstore.value.userNo) {
		const res = await axios.get(`https://live24.app/shorts24/content/in_product`, {
			params: {
				product_no: wstore.value.productNo,
				user_no: wstore.value.userNo,
				top_url: location.href,
				is_mobile: isMobile.value,
				user_agent: navigator.userAgent,
			},
		});
		if (res.data.code == 200) {
			return res.data.result;
		}
	}
};

const goPlayer = () => {
	window.open(shorts.value.shorts_url);
	shorts.value = null;
};

let video = null;
const activeBtn = ref(false);
const togglePlay = () => {
	if (!activeBtn.value) return;
	document.getElementById("l24werPlayer_player_s24lod").paused
		? document.getElementById("l24werPlayer_player_s24lod").play()
		: document.getElementById("l24werPlayer_player_s24lod").pause();

	isPlay.value = document.getElementById("l24werPlayer_player_s24lod").paused;
};

const toggleMuted = () => {
	if (!activeBtn.value) return;
	document.getElementById("l24werPlayer_player_s24lod").muted
		? (document.getElementById("l24werPlayer_player_s24lod").muted = false)
		: (document.getElementById("l24werPlayer_player_s24lod").muted = true);
};

let isVetical = ref(true);
let activeTimer = null;
let outActiveTimer = null;
let fadeAni = ref("animate__fadeIn");

const inActive = () => {
	isPlay.value = document.getElementById("l24werPlayer_player_s24lod").paused;
	if (!activeBtn.value) {
		fadeAni.value = "animate__fadeIn";
		activeBtn.value = true;
		clearTimeout(activeTimer);
		activeTimer = setTimeout(() => {
			outActive();
		}, 3000);
	}
};
const outActive = () => {
	fadeAni.value = "animate__fadeOut";
	clearTimeout(outActiveTimer);
	outActiveTimer = setTimeout(() => {
		activeBtn.value = false;
	}, 200);
};

const setVideoSize = () => {
	if (shorts.value.width > 0 && shorts.value.height > 0) {
		if (shorts.value.width > shorts.value.height) {
			isVetical.value = false;

			if (window.innerWidth < 600) {
				element.value = {
					x: -220,
					y: -100,
					width: 220,
					height: 130,
					isActive: false,
				};
			} else {
				element.value = {
					x: -280,
					y: -160,
					width: 290,
					height: 170,
					isActive: false,
				};
			}
		} else if (shorts.value.width == shorts.value.height) {
			if (window.innerWidth < 600) {
				element.value = {
					x: -180,
					y: -180,
					width: 180,
					height: 180,
					isActive: false,
				};
			} else {
				element.value = {
					x: -210,
					y: -210,
					width: 220,
					height: 220,
					isActive: false,
				};
			}
		}

		try {
			if (shorts.value.etc_data) {
				if (isMobile.value) {
					if (shorts.value.etc_data.x) {
						element.value.x = element.value.x + shorts.value.etc_data.x * -1;
					}
					if (shorts.value.etc_data.y) {
						element.value.y = element.value.y + shorts.value.etc_data.y * -1;
					}
				} else {
					if (shorts.value.etc_data.pcx) {
						element.value.x = element.value.x + shorts.value.etc_data.pcx * -1;
					}
					if (shorts.value.etc_data.pcy) {
						element.value.y = element.value.y + shorts.value.etc_data.pcy * -1;
					}
				}

				if (shorts.value.etc_data.round > -1) {
					element.value.round = shorts.value.etc_data.round;
				}
			}
		} catch (e) {}
	}
};

let initRunTs = false;
let initRunTsTimer = null;
let initRunWh = false;
let isSlowSite = false;
const initRun = () => {
	if (initRunTs) {
		return;
	}
	initRunWh = true;
	let initTimeSec = 100;
	if (isSlowSite) {
		initTimeSec = 300;
	}
	try {
		video = document.getElementById("l24werPlayer_player_s24lod");
		try {
			if (shorts.value.width != video.videoWidth && video.videoWidth > 0) {
				shorts.value.width = video.videoWidth;
			}
			if (shorts.value.height != video.videoHeight && video.videoHeight > 0) {
				shorts.value.height = video.videoHeight;
			}

			setVideoSize();

			if (video.videoWidth > 0 && video.videoHeight > 0) {
			} else {
				initRunTsTimer = setTimeout(() => {
					initRun();
				}, initTimeSec);
				return;
			}
		} catch (e) {}
		video.removeEventListener("play", () => {});
		video.removeEventListener("pause", () => {});
		video.removeEventListener("volumechange", () => {});

		video.addEventListener("play", () => {
			isPlay.value = true;
		});
		video.addEventListener("pause", () => {
			isPlay.value = false;
		});
		video.addEventListener("volumechange", () => {
			if (video.muted) {
				isMuted.value = true;
			} else {
				isMuted.value = false;
			}
		});

		toLoad.value = false;
		// setVideoSize();

		if (initRunWh && initRunWh) {
			initRunTs = true;
			setTimeout(() => {
				toLoad.value = true;
				isVideoReady.value = true;
			}, initTimeSec);
		} else {
			initRunTsTimer = setTimeout(() => {
				initRun();
			}, initTimeSec);
		}
	} catch (e) {
		initRunTsTimer = setTimeout(() => {
			initRun();
		}, initTimeSec);
	}
};

const resizeRun = () => {
	if (props.options.mobile) {
		wstore.value.mobile = true;
		wstore.value.colspan = 2;
		wstore.value.maxWidth = document.querySelector(".s24uuskr").clientWidth + "px";
	} else {
		if (window.innerWidth < 768) {
			wstore.value.mobile = true;
			wstore.value.colspan = 2;
			wstore.value.maxWidth = window.innerWidth - 20 + "px";
		} else {
			wstore.value.mobile = false;
			wstore.value.colspan = props.options.colspan;
			wstore.value.maxWidth = props.options.maxWidth;
			wstore.value.marginLeft = props.options.marginLeft;
			wstore.value.marginRight = props.options.marginRight;
		}
	}
};

onMounted(async () => {
	if (window.l24idtoLoad) return;
	if (window.l24idtoLoaTs) return;
	if (window.l24ToFastStart) return;

	console.log("run l24 floating");

	if (window.innerWidth < 600) {
		element.value = {
			x: -130,
			y: -200,
			width: 112.5,
			height: 200,
			isActive: false,
		};
	}

	wstore.value = { ...wstore.value, ...props.options };

	let parser = new UAParser(navigator.userAgent);
	if (parser.getDevice().type === "mobile") {
		isMobile.value = true;
	}

	if (top === self) {
		shorts.value = await getShortsInfo();

		if (shorts.value) {
			if (location.host.indexOf("tedwomanworld.co.kr") > -1) {
				isSlowSite = true;
				setTimeout(() => {
					initRun();
				}, 500);
			} else {
				initRun();
			}

			//마이하우스 사이트 버그로 인한 조치
			if (location.host.indexOf("myhouse.co.kr") > -1) {
				isHls.value = true;
			}
		}

		if (!window.l24idtoLoad) {
			toLoad.value = true;
		}
	} else {
		console.log("Shorts24App : iframe에서는 동작하지 않습니다.");
	}
});

onUnmounted(() => {
	try {
		video.removeEventListener("play", () => {});
		video.removeEventListener("pause", () => {});
		video.removeEventListener("volumechange", () => {});
	} catch (e) {}
});
</script>

<template>
	<div class="s24Wrap" v-if="shorts && toLoad" @click="inActive" @mouseleave="outActive">
		<div class="s24Float" style="--animate-duration: 0.2s">
			<DraggableResizableVue
				v-model:x="element.x"
				v-model:y="element.y"
				v-model:h="element.height"
				v-model:w="element.width"
				v-model:active="element.isActive"
				style="
					border: 0;

					overflow: hidden;
					filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
				"
				:style="{
					borderRadius: element.round > -1 ? element.round + 'px' : '15px',
				}"
				:minWidth="120"
				:minHeight="270"
				:maxWidth="200"
				:maxHeight="360"
				:resizable="false"
				:draggable="true"
			>
				<div class="s24btnWarp animate__animated" :class="fadeAni" v-if="activeBtn">
					<div style="display: flex; justify-content: space-between; align-items: center">
						<div>
							<button type="button" class="nostyle pointer" @click="goPlayer" style="padding: 8px">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="22"
									height="22"
									fill="#f2f2f2"
									viewBox="0 0 256 256"
								>
									<path
										d="M192,72V216a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V72a8,8,0,0,1,8-8H184A8,8,0,0,1,192,72Zm24-40H72a8,8,0,0,0,0,16H208V184a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32Z"
									></path>
								</svg>
							</button>
						</div>
						<div>
							<button type="button" class="nostyle pointer" style="padding: 8px" @click="shorts = null">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="22"
									height="22"
									fill="#ffffff"
									viewBox="0 0 256 256"
								>
									<path
										d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"
									></path>
								</svg>
							</button>
						</div>
					</div>
					<div
						class="flex-1"
						style="display: flex; flex-direction: column; align-items: center; justify-content: center"
						:style="{
							gap: isVetical ? '15px' : '5px',
							paddingBottom: isVetical ? '0px' : '10px',
						}"
					>
						<div>
							<button type="button" class="nostyle pointer" @click="togglePlay">
								<template v-if="isPlay">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="32"
										height="32"
										fill="#f2f2f2"
										viewBox="0 0 256 256"
									>
										<path
											d="M240,128a15.74,15.74,0,0,1-7.6,13.51L88.32,229.65a16,16,0,0,1-16.2.3A15.86,15.86,0,0,1,64,216.13V39.87a15.86,15.86,0,0,1,8.12-13.82,16,16,0,0,1,16.2.3L232.4,114.49A15.74,15.74,0,0,1,240,128Z"
										></path>
									</svg>
								</template>
								<template v-else>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="32"
										height="32"
										fill="#f2f2f2"
										viewBox="0 0 256 256"
									>
										<path
											d="M216,48V208a16,16,0,0,1-16,16H160a16,16,0,0,1-16-16V48a16,16,0,0,1,16-16h40A16,16,0,0,1,216,48ZM96,32H56A16,16,0,0,0,40,48V208a16,16,0,0,0,16,16H96a16,16,0,0,0,16-16V48A16,16,0,0,0,96,32Z"
										></path>
									</svg>
								</template>
							</button>
						</div>

						<div>
							<button
								type="button"
								class="nostyle pointer"
								style="
									padding: 8px 12px;
									color: #fff;
									background-color: rgba(75, 75, 75, 0.87);
									border-radius: 50px;
									font-size: 12px;
								"
								:style="isMobile ? 'zoom:0.8' : 'zoom:1'"
								@click="toggleMuted"
							>
								{{ isMuted ? "소리켜기" : "음소거" }}
							</button>
						</div>
					</div>
				</div>

				<div
					style="position: relative; width: 100%; height: 100%"
					:style="{
						opacity: isVideoReady ? 1 : 0,
					}"
				>
					<div
						style="position: absolute; top: 0; left: 0; right: 0; z-index: 3; width: 100%; height: 100%"
					></div>

					<UiHls
						v-if="isHls"
						:id="'s24lod'"
						:hlsUrl="shorts.hlsPlayUrl"
						:autoplay="true"
						:muted="true"
						:loop="true"
						style="width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 2"
						:style="{ objectFit: isVetical ? 'cover' : 'cover' }"
					/>
					<video
						:src="shorts.endpointurl"
						style="width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 2"
						:style="{ objectFit: isVetical ? 'cover' : 'cover' }"
						playsinline
						muted
						autoplay
						preload="metadata"
						id="l24werPlayer_player_s24lod"
						class="s24VideoPlayer"
						loop
						v-else
					/>
				</div>
			</DraggableResizableVue>
		</div>
	</div>
</template>

<style scoped>
.pointer {
	cursor: pointer;
}
.nostyle {
	border: none;
	background: none;
}
.flex-1 {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}
.s24btnWarp {
	background-color: rgba(0, 0, 0, 0.51);
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 10;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	cursor: move;
}
.s24Wrap {
	position: relative;
}
.s24Float video.s24VideoPlayer {
	border-radius: 5px;
	filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
	margin: 0 !important;
	position: relative !important;
	left: 0 !important;
}
.s24Float {
	width: 100%;

	aspect-ratio: 9/16;
	margin-right: 2.5em;
	margin-bottom: 2em;
	border-radius: 5px;
	position: relative;
}
.closeBtnWarp {
	position: absolute;
	right: -12px;
	top: -14px;
	z-index: 99;
	padding: 5px;
	cursor: pointer;
}

.closeBtn {
	background-color: #000;
	border-radius: 100%;
	width: 30px;
	height: 30px;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
@media (max-width: 768px) {
	.s24Float {
		margin-right: 15px;
		margin-bottom: 80px;
		max-width: 120px;
	}
}
@media (min-width: 768px) {
	.s24Float {
		margin-right: 30px;
		margin-bottom: 80px;
		max-width: 180px;
	}
}
</style>
