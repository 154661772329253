<script setup>
import { ref, computed, onMounted, defineProps, onUnmounted } from "vue";
import { useL24Store } from "../store/l24";
import showCaseBox from "./ui/showCaseBox.vue";
import UiSkl from "./ui/skl.vue";

const store = useL24Store();
const props = defineProps({
	options: {
		type: Object,
	},
});

//data()
const wstore = ref({
	marginTop: "0",
	marginBottom: "0",
	marginLeft: "0",
	marginRight: "0",
	maxWidth: "1280px",
	title: "",
	titleFontSize: "20px",
	cardGap: "20px",
	privew: false,
	mobile: false,
	limit: 30,
});

const config = computed(() => store.l24config || "");

const cacheTime = ref(10);
const isEnd = ref(false);
const init_load = ref(false);
const pageNo = ref(0);
const loading = ref(false);

const shorts1 = ref([]);
const shorts2 = ref([]);
const shorts3 = ref([]);
const shorts4 = ref([]);

const shuffleArray = (array) => {
	const arr = Array.from(array); // make a copy
	for (let i = arr.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[arr[i], arr[j]] = [arr[j], arr[i]];
	}
	return arr;
};

const ovCallBack = async () => {
	if (init_load.value && !isEnd.value && !loading.value) {
		console.log("!!ovCallBack");
		loading.value = true;
		await getPage();
	}
};
let lastRand = 0;
const getPage = async (page) => {
	if (wstore.value.mobile) {
		wstore.value.limit = 8;
	} else {
		wstore.value.limit = 12;
	}

	if (!page) {
		pageNo.value++;
	} else {
		pageNo.value = page;
	}

	if (pageNo.value > 10) {
		isEnd.value = true;
		return;
	}

	loading.value = true;
	await store
		.getShortsShowcase({
			row_count: wstore.value.limit,
			order_col: wstore.value.orderCol,
			page_no: pageNo.value,
			cache_time: cacheTime.value,
			have_product: "Y",
		})
		.then((res) => {
			try {
				let isr = 1;
				let setVideoCnt = 0;
				let isYbV = false;

				let shortsArray = [];
				let shortsArrayBase = [];
				for (let i = 0; i < store.shorts?.length; i++) {
					shortsArrayBase.push(store.shorts[i]);
				}

				shortsArray = shuffleArray(shortsArrayBase);

				for (let i = 0; i < shortsArray?.length; i++) {
					if (
						shortsArray[i].select_product_list?.length < 1 ||
						shortsArray[i].select_product_list?.length == undefined
					) {
						shortsArray[i].isVideo = true;
						shortsArray[i].isProduct = [];
					} else {
						shortsArray[i].isVideo = false;
						shortsArray[i].isProduct = shortsArray[i].select_product_list[0];
					}

					let rea3 = Math.floor(Math.random() * (5 - 1)) + 1;
					if (lastRand > 0) {
						if (lastRand == rea3) {
							rea3++;
							if (rea3 > 4) {
								rea3 = 4;
							}
						}
					}

					lastRand = rea3;

					if (i % rea3 == 0) {
						if (setVideoCnt < 8) {
							if (!isYbV) {
								shortsArray[i].isVideo = true;
								setVideoCnt++;
								isYbV = true;
							} else {
								isYbV = false;
							}
						}
					}

					if (isr == 1) {
						shorts1.value.push(shortsArray[i]);
						isr = 2;
					} else if (isr == 2) {
						shorts2.value.push(shortsArray[i]);
						if (wstore.value.mobile) {
							isr = 1;
						} else {
							isr = 3;
						}
					} else if (isr == 3) {
						shorts3.value.push(shortsArray[i]);
						isr = 4;
					} else if (isr == 4) {
						shorts4.value.push(shortsArray[i]);
						isr = 1;
					}
				}

				if (shortsArray.length < wstore.value.limit) {
					isEnd.value = true;
					console.log("isEnd true");
				}
			} catch (e) {
				console.error(e);
			} finally {
				init_load.value = true;
				loading.value = false;
			}
		});
};

//mounted()
onMounted(async () => {
	//계정 환경설정 로드
	await store.getL24Config();

	wstore.value = { ...wstore.value, ...props.options };

	wstore.value.colspan = parseInt(wstore.value.colspan);
	wstore.value.limit = parseInt(wstore.value.limit);

	loading.value = true;

	if (document.querySelector(".l24TraConter").clientWidth < 762) {
		wstore.value.mobile = true;
		wstore.value.cardGap = "15px";
		wstore.value.marginTop = "20px";
		wstore.value.marginBottom = "20px";
		wstore.value.marginLeft = "10px";
		wstore.value.marginRight = "10px";
	} else {
		wstore.value.mobile = false;
		wstore.value.cardGap = "35px";

		wstore.value.marginTop = "50px";
		wstore.value.marginBottom = "50px";
		wstore.value.marginLeft = "5px";
		wstore.value.marginRight = "5px";
	}

	wstore.value.maxWidth = "1280px";

	await getPage();

	try {
		const io = new IntersectionObserver(ovCallBack, { threshold: 0.7 });
		io.observe(document.getElementById("ovTargetDiv"));
	} catch (e) {}

	window.addEventListener("resize", () => {
		if (document.querySelector(".l24TraConter").clientWidth < 762) {
			wstore.value.mobile = true;
		} else {
			wstore.value.mobile = false;
		}
	});
});
</script>

<template>
	<div
		:style="{
			backgroundColor: wstore.backgroundColor,
		}"
		class="l24-wrapper l24TraConter"
		:class="{ 'l24-mobile': wstore.mobile }"
	>
		<div
			v-if="init_load"
			style="margin: auto"
			:style="{
				maxWidth: wstore.maxWidth,
				width: '100%',
				paddingTop: wstore.marginTop,
				paddingBottom: wstore.marginBottom,
				paddingLeft: wstore.marginLeft,
				paddingRight: wstore.marginRight,
			}"
		>
			<div
				:style="{
					gap: wstore.cardGap,
				}"
				style="display: flex"
			>
				<div class="flex-1">
					<showCaseBox :shorts="shorts1" :wstore="wstore" />

					<div v-if="pageNo < 2 && shorts1?.length < 1">
						<UiSkl
							width="100%"
							height="100%"
							style="margin-bottom: 10px; aspect-ratio: 1/1"
							:borderRadius="0"
						/>
					</div>
				</div>
				<div class="flex-1">
					<showCaseBox :shorts="shorts2" :wstore="wstore" />

					<div v-if="pageNo < 2 && shorts2?.length < 1">
						<UiSkl
							width="100%"
							height="100%"
							style="margin-bottom: 10px; aspect-ratio: 1/1"
							:borderRadius="0"
						/>
					</div>
				</div>
				<div class="flex-1" v-if="!wstore.mobile">
					<showCaseBox :shorts="shorts3" :wstore="wstore" />
					<div v-if="pageNo < 2 && shorts3?.length < 1">
						<UiSkl
							width="100%"
							height="100%"
							style="margin-bottom: 10px; aspect-ratio: 1/1"
							:borderRadius="0"
						/>
					</div>
				</div>
				<div class="flex-1" v-if="!wstore.mobile">
					<showCaseBox :shorts="shorts4" :wstore="wstore" />
					<div v-if="pageNo < 2 && shorts4?.length < 1">
						<UiSkl
							width="100%"
							height="100%"
							style="margin-bottom: 10px; aspect-ratio: 1/1"
							:borderRadius="0"
						/>
					</div>
				</div>
			</div>

			<div id="ovTargetDiv" style="height: 10px"></div>
		</div>
	</div>
</template>

<style>
.l24TraConter {
	container-name: showcase-main-container;
	container-type: inline-size;
}
</style>
<style scoped>
.l24TraConter {
	display: flex;
	justify-items: center;
}
.flex-1 {
	flex: 1 1 auto;
	max-width: 25%;
	min-width: 24%;
}

.l24-mobile .grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr) !important;
	grid-gap: 10px;
}

@container showcase-main-container  (max-width: 768px) {
	.flex-1 {
		flex: 1;
		max-width: 50%;
		min-width: 48%;
	}
}
</style>
